<div *ngIf="showSaldoFeature" class="time-tracker-container">
  <div *ngIf="!isDataLoaded" class="loading-container">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <div *ngIf="isDataLoaded">
    <div class="time-info">
      <span>{{ totalWorkTimeSeconds | secondsToTime }}/{{ totalTargetTime }}
        <span *ngIf="exceededPercentage > 0" class="exceeded-time positive">(+{{ exceededTime }})</span>
      </span>
      <button class="info-button" mat-mini-fab (click)="togglePopup()">
        <mat-icon class="mat-icon" aria-hidden="false" aria-label="Info" color="primary">
          info_outline
        </mat-icon>
      </button>
    </div>
    <div class="progress-bar-container">
      <div class="progress-bar" [style.width.%]="workedPercentage"></div>
      <div *ngIf="exceededTime" class="exceeded-line" [style.right.%]="exceededPercentage"></div>
    </div>
    <div class="remainder-info">
      Saldo Vormonat:
      <span [ngClass]="{'negative': lastMonthRemainderSeconds < 0, 'positive': lastMonthRemainderSeconds > 0}">
        {{ lastMonthRemainder }}
      </span>
      <div *ngIf="totalRemainderHours > 30 && totalRemainderHours <= 40 &&  !remove40hCap" class="warning-hours">
        <mat-icon class="mat-mdc-icon-button full-width" disabled="true" aria-hidden="false" aria-label="Info">
          info_outline
        </mat-icon>
        <p>Du hast {{totalRemainder}} Überstunden aufgebaut.</p>
      </div>

      <div *ngIf="totalRemainderHours > 40 && !remove40hCap" class="row warning-hours">
        <mat-icon class="mat-icon-button mat-warn critical full-width" aria-hidden="false" aria-label="Info">
          info_outline
        </mat-icon>
        <p>Du hast <span class="accent-hours">40 Überstunden </span> überschritten.</p>
      </div>
    </div>

    <div class="flyout-popup" *ngIf="popupVisible">
      <div class="flyout-header">
        <span>Stundenübersicht des laufenden Monats</span>
        <button mat-mini-fab (click)="togglePopup()">
          <mat-icon class="close-button" color="primary" aria-label="Close">close</mat-icon>
        </button>
      </div>
      <div class="timebox">
        <p>{{ totalWorkTimeSeconds | secondsToTime }}/{{ totalTargetTime }}
          <span *ngIf="exceededPercentage > 0" class="exceeded-time positive">(+{{ exceededTime }})</span>
        </p>
        <div class="progress-bar-container">
          <div class="progress-bar" [style.width.%]="workedPercentage"></div>
          <div *ngIf="exceededTime" class="exceeded-line" [style.right.%]="exceededPercentage"></div>
        </div>
        <p>
          Saldo Vormonat:
          <span [ngClass]="{'negative': lastMonthRemainderSeconds < 0, 'positive': lastMonthRemainderSeconds > 0}">
            {{ lastMonthRemainder }}
          </span>
        </p>
      </div>
      <div class="flyout-content">
        <div class="row">
          <span>Soll-Stunden abzgl. Urlaub, Feiertag, Krankheit</span>
          <span class="summary-item">{{ totalTargetTime }}</span>
        </div>
        <div class="row">
          <span>Gebuchte Stunden</span>
          <span class="summary-item">{{ totalWorkTimeSeconds | secondsToTime }}</span>
        </div>
        <hr class="blue-divider" />
        <div class="row">
          <span>Saldo laufender Monat</span>
          <span class="summary-item" [ngClass]="{'negative': currentRemainderSeconds < 0, 'positive': currentRemainderSeconds > 0}">
            {{ currentRemainderTime }}
          </span>
        </div>
        <div class="row">
          <span>Saldo Vormonat</span>
          <span class="summary-item" [ngClass]="{'negative': lastMonthRemainderSeconds < 0, 'positive': lastMonthRemainderSeconds > 0}">
            {{ lastMonthRemainder }}
          </span>
        </div>
        <div class="row" *ngIf="saldoChangeDisplay !== '--'">
          <span>Korrektur Back Office</span>
          <span class="summary-item" [ngClass]="{'negative': saldoChangeValue < 0, 'positive': saldoChangeValue > 0}">
          {{ saldoChangeDisplay }}
        </span>
        </div>
        <hr class="blue-divider" />
        <div class="row">
          <span>Saldo Gesamt</span>
          <span class="summary-item" [ngClass]="{'negative': totalRemainderSeconds < 0, 'positive': totalRemainderSeconds > 0}">
            {{ totalRemainder }}
          </span>
        </div>
        <div *ngIf="exceededTimeHours != 0 && !remove40hCap" class="row lost-hours">
          <span>Verfallene Stunden</span>
          <span class="summary-item negative">
            -{{ exceededTimeHoursFormat }}
          </span>
        </div>
        <div *ngIf="totalRemainderHours > 30 && totalRemainderHours <= 40 && !remove40hCap" class="row warning-hours">
          <mat-icon class="mat-mdc-icon-button full-width" disabled="true" aria-hidden="false" aria-label="Info">
            info_outline
          </mat-icon>
          <p>Du hast {{totalRemainder}} Überstunden aufgebaut. Bitte beachte, dass ab 40 Stunden weitere Stunden verfallen.</p>
        </div>

        <div *ngIf="totalRemainderHours > 40 && !remove40hCap" class="row warning-hours">
          <mat-icon class="mat-icon-button mat-warn critical full-width" aria-hidden="false" aria-label="Info">
            info_outline
          </mat-icon>
          <p>Du hast <span class="accent-hours">40 Überstunden </span> überschritten. Weitere Überstunden verfallen! Bitte informiere deine Führungskraft.</p>
        </div>
      </div>
    </div>
  </div>
</div>
