import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {TimeNavigationService} from '../../services/time-navigation.service'
import {LocalTimeEntriesService} from '../../services/repository/local-time-entries.service'
import {DateUtils, MONTH_NAMES} from '../../util/date-utils'
import {CustomIcon} from '../../services/custom-icons.service'

@Component({
  selector: 'app-month-navigation',
  templateUrl: './month-navigation.component.html',
  styleUrls: ['./month-navigation.component.scss']
})
export class MonthNavigationComponent implements OnInit, OnDestroy {

  constructor(private timeNavigationService: TimeNavigationService,
              public localTimeEntriesService: LocalTimeEntriesService) {
  }

  monthLabel = ''
  @Input() enableTodayButton: boolean
  private dayChangedSubscription: Subscription

  protected readonly CustomIcon = CustomIcon

  ngOnInit() {
    this.dayChangedSubscription = this.timeNavigationService.currentDay$.subscribe(() => {
      this.updateMonthLabel()
    })
    this.updateMonthLabel()
  }

  navigateMonth(deltaMonth: number) {
    let date: Date
    if (deltaMonth == 0) {
      date = DateUtils.today
    } else {
      date = new Date(this.timeNavigationService.currentDay.getFullYear(), this.timeNavigationService.currentDay.getMonth() + deltaMonth, 1)
    }
    this.timeNavigationService.navigateTo(date);
  }

  ngOnDestroy() {
    this.dayChangedSubscription.unsubscribe()
  }

  private updateMonthLabel() {
    const monthIndex = this.timeNavigationService.currentDay.getMonth()
    this.monthLabel = `${MONTH_NAMES[monthIndex]} ${this.timeNavigationService.currentDay.getFullYear()}`
  }
}
