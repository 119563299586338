import {Injectable} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'

export enum CustomIcon {
  DIAMOND = 'diamond',
  NOTIFICATIONS_NONE = 'outline-notifications_none-24px',
  NOTIFICATIONS_ACTIVE = 'outline-notifications_active-24px',
  PUBLISHED_WITH_CHANGES = 'published_with_changes',


  ABSENCE_CHILD_CARE = 'absence_child_care',
  ABSENCE_CHILD_CARE_2 = 'absence_child_care_22',
  ABSENCE_EDUCATION = 'absence_education',
  ABSENCE_EDUCATION_2 = 'absence_education_2',
  ABSENCE_HOLIDAY = 'absence_holiday',
  ABSENCE_HOLIDAY_2 = 'absence_holiday_2',
  ABSENCE_MATERNITY_PROTECTION = 'absence_maternity-protection',
  ABSENCE_MATERNITY_PROTECTION_2 = 'absence_maternity-protection_2',
  ABSENCE_MORE = 'absence_more',
  ABSENCE_MORE_2 = 'absence_more_2',
  ABSENCE_OVERTIME = 'absence_overtime',
  ABSENCE_OVERTIME_2 = 'absence_overtime_2',
  ABSENCE_PARENTAL_LEAVE = 'absence_parental_leave',
  ABSENCE_PARENTAL_LEAVE_2 = 'absence_parental_leave_2',
  ABSENCE_SABBATICAL = 'absence_sabbatical',
  ABSENCE_SABBATICAL_2 = 'absence_sabbatical_2',
  ABSENCE_SCHOOL = 'absence_school',
  ABSENCE_SCHOOL_2 = 'absence_school_2',
  ABSENCE_SICK = 'absence_sick',
  ABSENCE_SICK_2 = 'absence_sick_2',
  ABSENCE_SPECIAL_LEAVE = 'absence_special_leave',
  ABSENCE_SPECIAL_LEAVE_2 = 'absence_special_leave_2',
  ABSENCE_UNKNOWN = 'absence_unknown',
  ABSENCE_UNRECORDED = 'absence_unrecorded',
  ABSENCE_UNRECORDED_2 = 'absence_unrecorded_2',
  ABSENCE_VACATION = 'absence_vacation',
  ABSENCE_VACATION_2 = 'absence_vacation_2',
  ABSENCE_VACATION_UNPAID = 'absence_vacation_unpaid',
  ABSENCE_VACATION_UNPAID_2 = 'absence_vacation_unpaid_2',
  ABSENCE_WORK_TRAVEL = 'absence_work_travel',
  ABSENCE_WORK_TRAVEL_2 = 'absence_work_travel_2',
}

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }

  registerCustomIcons(): void {
    for (const icon of Object.values(CustomIcon)) {
      this.addCustomSvgIcon(icon)
    }
  }

  private addCustomSvgIcon(name: string, assetName: string = name): void {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `/assets/icons/${assetName}.svg`,
      ),
    )
  }
}

export function customIconExists(icon: string): boolean {
  return CustomIcon[icon] != null
}
