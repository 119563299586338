import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {GraphqlCollectorService} from '../http/graphql-collector.service'
import {SessionService} from '../session.service'
import {GraphQLQuery} from '../../util/graphql-executor'
import {catchError} from 'rxjs/operators'
import {environment} from '../../../environments/environment'


export const UNRECORDED_ABSENCE_DISPLAY_NAME = 'Keine Abwesenheit'

@Injectable({
  providedIn: 'root'
})
export class AbsenceEntryTypeService {
  private repoURL: string
  private allSubject$ = new BehaviorSubject<AbsenceEntryTypeEntity[]>([])
  private holidayIdSubject$ = new BehaviorSubject<number>(null)

  constructor(private httpClient: HttpClient,
              private graphQlService: GraphqlCollectorService,
              private sessionService: SessionService) {
    this.repoURL = `${environment.serverUrl}/absenceTypes`

    this.sessionService.loginData$.subscribe(loginData => {
      if (loginData.isValid) {
        this.requestAll()
      }
    })
  }

  get all$(): Observable<AbsenceEntryTypeEntity[]> {
    return this.allSubject$.asObservable()
  }

  get all(): AbsenceEntryTypeEntity[] {
    return this.allSubject$.getValue()
  }

  get holidayId$(): Observable<number> {
    return this.holidayIdSubject$.asObservable()
  }

  get holidayId(): number {
    return this.holidayIdSubject$.getValue()
  }

  static allGraphsQLQuery(): GraphQLQuery {
    return {
      function: 'absenceTypes',
      variables: [],
      fieldBody: ABSENCE_ENTRY_TYPE_TEMPLATE
    }
  }

  requestAll(): void {
    this.graphQlService
      .query(AbsenceEntryTypeService.allGraphsQLQuery())
      .pipe(
        catchError(error => {
          console.error('Error fetching absence entry types', error)
          return of([])
        })
      )
      .subscribe(absenceEntryTypes => {
        this.allSubject$.next(absenceEntryTypes)
        this.holidayIdSubject$.next(absenceEntryTypes.find(absenceType => absenceType.holiday === true)?.id)
      })
  }

  /* APIs below are not yet converted to BloC pattern */

  getById(id: number): AbsenceEntryTypeEntity {
    return this.all.find(value => value.id === id)
  }

  update(absenceEntryType: AbsenceEntryTypeEntity): void {
    this.httpClient.put(`${this.repoURL}/${absenceEntryType.id}`, absenceEntryType)
      .pipe(
        catchError(error => {
          console.error('Error update absence entry types', error)
          return of([])
        })
      ).subscribe(() => this.requestAll())
  }

  create(absenceEntryType: AbsenceEntryTypeEntity): void {
    this.httpClient.post(this.repoURL, absenceEntryType)
      .pipe(
        catchError(error => {
          console.error('Error create absence entry types', error)
          return of([])
        })
      ).subscribe(() => this.requestAll())
  }

  delete(absenceEntryType: AbsenceEntryTypeEntity): void {
    absenceEntryType.archived = true
    this.update(absenceEntryType)
  }

  restore(absenceEntryType: AbsenceEntryTypeEntity): void {
    absenceEntryType.archived = false
    this.update(absenceEntryType)
  }
}

export interface AbsenceEntryTypeEntity {
  id: number
  name: string
  description: string
  kind: Types
  ratio: number
  archived: boolean
  holiday: boolean
  isTimeCompensation: boolean
  overtimeExclusion: boolean
  externalName: string
}

export enum Types {
  leave = 'Leave',
  other = 'Other',
}

export enum TypeNames {
  leave = 'Abwesenheit',
  other = 'Sonstiges',
}

export const ABSENCE_ENTRY_TYPE_TEMPLATE =
  `
    id,
    name,
    description,
    kind,
    ratio,
    archived,
    holiday,
    externalName,
    isTimeCompensation,
    overtimeExclusion
 `

