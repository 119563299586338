import {Injectable} from '@angular/core'
import {BehaviorSubject, combineLatest, Observable} from 'rxjs'
import {TimeNavigationService} from '../time-navigation.service'
import {DateUtils} from '../../util/date-utils'
import {v4 as uuidv4} from 'uuid'
import {User, UserService} from './user.service'
import {TimeEntriesService, TimeEntryEntity} from './time-entries.service'
import {
  mergeDataWithModifiableObject,
  ModifiableDataObject,
  ModifiableField,
  modifyModifiableObject,
  Synchronizer
} from '../../util/synchronizer'
import {LocalUserPropertiesService} from '../local-user-properties.service'
import {Bouncer} from '../../util/bouncer'
import {AzureLogging} from '../azure-logging.service'
import {deepCopy} from '../../util/copy'
import {TimeEntryMutationResult} from './graphql/graphql-types'
import {map} from 'rxjs/operators'

const TIMEBOX_LOCAL_TIME_ENTRIES_KEY = 'TimeboxLocalTimeEntriesV5'
const TIME_ENTRIES_BOUNCE_TIMEOUT = 50
const TIME_ENTRY_ERROR_MAX_RETRIES = 3

@Injectable({
  providedIn: 'root'
})
export class LocalTimeEntriesService extends Synchronizer<LocalTimeEntry> {

  private bouncer = new Bouncer(TIME_ENTRIES_BOUNCE_TIMEOUT)

  private errorRetryCounter: Map<string, number> = new Map<string, number>()

  constructor(private timeNavigationService: TimeNavigationService,
              private timeEntriesService: TimeEntriesService,
              private userService: UserService,
              private userPropertiesService: LocalUserPropertiesService) {
    super()

    this.gatherDataForBulkRequest = true
  }

  private _currentMonth$ = new BehaviorSubject<TimeEntry[]>([])

  get currentMonth$(): Observable<TimeEntry[]> {
    return this._currentMonth$
  }

  private _currentTimeEntriesOverflow$ = new BehaviorSubject<TimeEntry[]>([])

  timeEntries$: Observable<TimeEntry[]> = combineLatest([
    this.currentMonth$,
    this.currentTimeEntriesOverflow$
  ]).pipe(
    map(([currentMonth, currentTimeEntriesOverflow]) => {
      return [...currentMonth, ...currentTimeEntriesOverflow];
    })
  );

  get currentTimeEntriesOverflow$(): Observable<TimeEntry[]> {
    return this._currentTimeEntriesOverflow$
  }

  get currentMonth(): TimeEntry[] {
    return this._currentMonth$.getValue()
  }

  get currentMonthOverflow(): TimeEntry[] {
    return this._currentTimeEntriesOverflow$.getValue()
  }

  setTimeEntry(date: Date, topicId: number, timeEntryTypeId: number, timeSpanInSecs: number): void {

    const timeEntry = this.dataArray.find((entry) => {
      return DateUtils.isSameDate(date, DateUtils.stringToDate(entry.date)) &&
        topicId === entry.topicId &&
        timeEntryTypeId === entry.timeEntryTypeId
    })
    if (timeEntry) {
      this.modifyLocalTimeEntry(timeEntry, timeSpanInSecs)
    } else {
      if (timeSpanInSecs !== 0) {
        const newEntry: LocalTimeEntry = {
          uuid: uuidv4(),
          topicId,
          timeEntryTypeId,
          date: DateUtils.dateToString(date),
          modified: null,
          timeSpanInSecs: {
            value: null,
            modified: false
          },
          mergedFrom: [],
          tracked: false
        }
        this.dataArray.push(newEntry)
        this.modifyLocalTimeEntry(newEntry, timeSpanInSecs)
      }
    }
  }

  removeAllByPredicate(predicate: (entry: TimeEntry) => boolean): void {
    this.dataArray
      .filter(localEntry => predicate(toTimeEntry(localEntry)))
      .forEach((localEntry) => {
        this.modifyLocalTimeEntry(localEntry, 0)
      })
    this.updateListenersForCurrentMonthObservable().then(() => {
      // this.saveLocalState().then()
    })
  }

  public async loadLocalState(): Promise<void> {
    const localEntries = await this.userPropertiesService.getProperty(TIMEBOX_LOCAL_TIME_ENTRIES_KEY, null)

    if (localEntries != null) {
      this.dataArray = localEntries
      this.updateListenersForCurrentMonthObservable().then()
    }
  }

  protected async fetchRemoteData(): Promise<TimeEntryEntity[]> {
    return this.timeEntriesService.fetchAllUnsubmittedTimeEntries()
  }

  protected async mergeRemoteDataIntoLocalData(remoteEntries: any[]): Promise<void> {
    this.addRemoteEntriesToLocalStore(remoteEntries)
  }

  public async postSynchronize(): Promise<void> {
    await this.deleteDuplicatedEntriesOnRemote()

    this.dataArray.forEach(localEntry => localEntry.mergedFrom = [])
    this.updateListenersForCurrentMonthObservable().then()
  }

  public async saveLocalState(): Promise<void> {
    this.userPropertiesService.setProperty(TIMEBOX_LOCAL_TIME_ENTRIES_KEY, this.dataArray).then()
  }

  /* ######################   Synchronization   ###################### */

  isAvailableFor(user: User): boolean {
    return true
  }

  private async deleteDuplicatedEntriesOnRemote(): Promise<boolean[]> {
    const promises: Promise<boolean>[] = []

    this.dataArray.map((localTimeEntry) => {
      promises.push(...localTimeEntry.mergedFrom
        .slice(1, localTimeEntry.mergedFrom.length)
        .map(timeEntry => timeEntry.id)
        .map(id => this.timeEntriesService.delete(id).then(IS_RESULT_OK))
      )
    })

    return Promise.all(promises)
  }

  protected async pushDataArrayToRemote(localTimeEntries: LocalTimeEntry[]): Promise<TimeEntryMutationResult> {
    return this.timeEntriesService.pushEntitiesToRemote(
      localTimeEntries.map(localEntry => {
        return toTimeEntryEntity(localEntry, this.userService.me.id)
      })
    )
  }

  private timeSpanSumOfRemoteEntries(remoteEntries: TimeEntryEntity[]): number {
    return remoteEntries.reduce((acc, mergedFromEntry) => acc + mergedFromEntry.gross, 0)
  }

  private deleteLocalTimeEntry(uuid: string): void {
    this.dataArray = this.dataArray.filter((entry) => entry.uuid !== uuid)
  }

  private revertOrDeleteLocalTimeEntry(localTimeEntry: LocalTimeEntry, onRevert: () => void, onDelete: () => void): void {
    if (localTimeEntry.tracked) {
      mergeDataWithModifiableObject({
        timeSpanInSecs: this.timeSpanSumOfRemoteEntries(localTimeEntry.mergedFrom)
      }, localTimeEntry)

      // emptying the array prevents the next step to delete the remote entries
      localTimeEntry.mergedFrom = localTimeEntry.mergedFrom.slice(0, 1)
      onRevert()
    } else {
      this.deleteLocalTimeEntry(localTimeEntry.uuid)
      onDelete()
    }
  }

  protected handleServerModificationResponseForModifiableObject(
    response: any,
    modifiedData: LocalTimeEntry,
    prePushData: LocalTimeEntry
  ): void {
    const errorOfTimeEntry = response.errors.find(error => error.id === modifiedData.uuid)
    if (errorOfTimeEntry) {
      const userName = this.userService.me.firstName + ' ' + this.userService.me.lastName

      switch (errorOfTimeEntry.status) {
        case 403: {
          this.revertOrDeleteLocalTimeEntry(modifiedData, () => {
            const userIdOfTimeEntry = modifiedData.mergedFrom[0].userFK
            if (this.userService.me.id !== userIdOfTimeEntry) {
              const message = 'Authorization failed: User is not allowed to manipulate time entries of other users.'
              AzureLogging.logError(new Error(userName + ':\n' + message))

              return
            }

            const message = `An unknown error occurred while handling response errors in LocalTimeEntriesService!\n
              Response: \n${JSON.stringify(deepCopy(response))}\n
              Modified Data: \n${JSON.stringify(deepCopy(modifiedData))}\n
              Pre-Synchronization Data: \n${JSON.stringify(deepCopy(prePushData))}\n
            `
            console.error(message)
            AzureLogging.logError(new Error(userName + ':\n' + message))
          }, () => {
            const message = `An unknown error occurred! Resetting local changes and restart synchronisation!
              Response: ${JSON.stringify(deepCopy(response))}
              Modified Data: ${JSON.stringify(deepCopy(modifiedData))}
              Pre-Synchronization Data: ${JSON.stringify(deepCopy(prePushData))}
            `
            console.error(message)
            AzureLogging.logError(new Error(userName + ':\n' + message))
          })

          break
        }
        case 404: {
          this.deleteLocalTimeEntry(errorOfTimeEntry.id)

          const message = 'Time-entry with id ' + errorOfTimeEntry.id + ' not found in database. Deleting locally...'
          console.warn(message)
          AzureLogging.logError(new Error(userName + ':\n' + message))

          break
        }
        case 409: {
          this.revertOrDeleteLocalTimeEntry(modifiedData, () => {
          }, () => {
            this._modified$.next(true)
          })

          const message = 'Cannot modify time-entry because month is already submitted!'
          AzureLogging.logError(new Error(userName + ':\n' + message))
          console.error(message)

          break
        }
        case 500: {
          const uuid = modifiedData.uuid
          if (this.errorRetryCounter.get(uuid) === undefined) {
            this.errorRetryCounter.set(uuid, 0)
          }

          const newCount = this.errorRetryCounter.get(uuid) + 1
          this.errorRetryCounter.set(uuid, newCount)

          const message = 'Could not update time-entry on date ' + modifiedData.date + ' because of an unknown server error. Saving locally for next synchronisation!'
          console.warn(message)

          if (newCount < TIME_ENTRY_ERROR_MAX_RETRIES) {
            modifiedData.modified = true
            this._modified$.next(true)

            break
          } else {
            this.errorRetryCounter.delete(modifiedData.uuid)

            let action = 'Deleted'
            this.revertOrDeleteLocalTimeEntry(modifiedData,
              () => {
                action = 'Reverted'
              },
              () => {
              }
            )

            AzureLogging.logError(new Error(userName + ':\n' +
              action + ' timeEntry due to unknown server error: \n' +
              errorOfTimeEntry.message))
          }

          return
        }
      }
    } else {
      this.errorRetryCounter.delete(modifiedData.uuid)
      modifiedData.tracked = true
    }

    this.errorRetryCounter.delete(modifiedData.uuid)
  }

  protected keepAfterSynchronization(data: LocalTimeEntry): boolean {
    return data.timeSpanInSecs.value > 0
  }

  private modifyLocalTimeEntry(entry: LocalTimeEntry, timeSpan: number): void {
    if (timeSpan != entry.timeSpanInSecs.value) {
      if (!entry.tracked && timeSpan <= 0) {
        this.dataArray = this.dataArray.filter(localEntry => localEntry.uuid !== entry.uuid)
      } else {
        modifyModifiableObject({timeSpanInSecs: timeSpan}, entry)
      }

      this.updateListenersForCurrentMonthObservable().then(() => {
        // this.saveLocalState().then()
      })

      this._modified$.next(true)
    }
  }

  private addRemoteEntriesToLocalStore(remoteTimeEntries: TimeEntryEntity[]): void {
    remoteTimeEntries
      .forEach((remoteEntry) => {
        const localEntry = this.dataArray.find((entry) => hashLocalEntry(entry) === localEntryHashOf(remoteEntry))
        if (localEntry !== undefined) {
          localEntry.mergedFrom.push(remoteEntry)
          localEntry.tracked = true
        } else {
          this.dataArray.push(newLocalTimeEntryBasedOn(remoteEntry))
        }
      })

    this.dataArray
      .filter(entry => entry.tracked && !entry.modified)
      .forEach(entry => {
        const data = {
          timeSpanInSecs: this.timeSpanSumOfRemoteEntries(entry.mergedFrom)
        }

        mergeDataWithModifiableObject(data, entry)
      })
  }

  private async updateListenersForCurrentMonthObservable(): Promise<void> {
    return this.bouncer.run(() => {
      const validEntries = this.dataArray.filter(entry => entry.timeSpanInSecs.value > 0)

      const entriesCurrentMonth = validEntries
        .filter(entry => DateUtils.isInMonth(DateUtils.stringToDate(entry.date), this.timeNavigationService.currentMonth))
        .map(toTimeEntry)

      const entriesCurrentMonthOverflow = validEntries
        .filter(entry => !DateUtils.isInMonth(DateUtils.stringToDate(entry.date), this.timeNavigationService.currentMonth))
        .map(toTimeEntry)

      this._currentTimeEntriesOverflow$.next(entriesCurrentMonthOverflow)
      this._currentMonth$.next(entriesCurrentMonth)
    })
  }

  protected getDataIdentifier(data: LocalTimeEntry): number | string {
    return data.uuid
  }

  /* ################################################################# */

}

function toTimeEntryEntity(localEntry: LocalTimeEntry, userId: number): TimeEntryEntity {
  return {
    id: localEntry.uuid,
    topicFK: localEntry.topicId,
    timeEntryTypeFK: localEntry.timeEntryTypeId,
    userFK: userId,
    date: localEntry.date,
    gross: localEntry.timeSpanInSecs.value,
    net: localEntry.timeSpanInSecs.value
  }
}

function newLocalTimeEntryBasedOn(baseEntry: TimeEntryEntity) {
  return {
    uuid: baseEntry.id,
    topicId: baseEntry.topicFK,
    timeEntryTypeId: baseEntry.timeEntryTypeFK,
    date: baseEntry.date,
    timeSpanInSecs: {
      value: null,
      modified: false
    },
    modified: false,
    mergedFrom: [baseEntry],
    tracked: true
  }
}

function toTimeEntry(localEntry: LocalTimeEntry): TimeEntry {
  return {
    topicId: localEntry.topicId,
    timeEntryTypeId: localEntry.timeEntryTypeId,
    date: DateUtils.stringToDate(localEntry.date),
    timeSpanInSecs: localEntry.timeSpanInSecs.value
  }
}

// Why hash function when uuid is unique?
function hashLocalEntry(entry: LocalTimeEntry): string {
  return `${entry.date}-${entry.topicId}-${entry.timeEntryTypeId}`
}

function localEntryHashOf(timeEntryEntity: TimeEntryEntity): string {
  return hashLocalEntry(newLocalTimeEntryBasedOn(timeEntryEntity))
}


interface LocalTimeEntry extends ModifiableDataObject {
  uuid: string
  topicId: number
  timeEntryTypeId: number
  date: string
  timeSpanInSecs: ModifiableField<number>
  mergedFrom: TimeEntryEntity[]
}

export interface TimeEntry {
  topicId: number
  timeEntryTypeId: number
  date: Date
  timeSpanInSecs: number
}

const SERVER_RESPONSE_ALL_OK = 2
const IS_RESULT_OK: (TimeEntryModificationResult) => boolean = response => {
  return response.success == SERVER_RESPONSE_ALL_OK
}
