import {Component, EventEmitter, Input, OnInit, Output, signal, ViewChild} from '@angular/core'
import {NG_VALUE_ACCESSOR} from '@angular/forms'
import {MatSelect} from '@angular/material/select'


export interface SelectOption<T> {
  displayName: string,
  value: T,
  disabled?: boolean
}

export interface SelectOptionWithIcon<T> extends SelectOption<T> {
  icon: string
}


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: SelectComponent}]
})
export class SelectComponent<T> implements OnInit {

  constructor() {
  }

  @Input() errorMessage = 'Element konnte nicht gefunden werden!'
  @Input() options: SelectOption<T>[] | SelectOptionWithIcon<T>[]
  @Input() canHandleNullSelection = false
  // @Input() getLabel: (value: T) => string
  @Input() placeholder: string
  @Input() selectedOption: T
  @Output() selectedOptionChange = new EventEmitter<T>()
  @Output() selected = new EventEmitter<T>()
  @ViewChild(MatSelect, {static: true}) select: MatSelect

  _open = false

  _error = ''

  @Input() compareSelectedWithOptions: (arg1: T, arg2: T) => boolean = (arg1, arg2) => arg1 === arg2

  toggleOptions(): void {
    this.select.toggle()
  }

  ngOnInit(): void {
    this.selectedOption = this.selectedOption ? this.selectedOption : null
  }

  onSelected(selectedValue: T): void {
    this.selected.emit(selectedValue)
    this.selectedOptionChange.emit(selectedValue)
  }

  getSelectTriggerData(): [string, string] {
    if (this.options && this.options.length > 0) {
      const element = this.options.find((ele) => {
          return this.compareSelectedWithOptions(ele.value, this.selectedOption)
        }
      )

      if (element) {
        this._error = ''
        return [element.displayName, (element as SelectOptionWithIcon<T> | null)?.icon]
      } else {
        this._error = this.errorMessage
        return ['null', null]
      }
    } else {
      return null
    }
  }

  isSelectOptionsWithIcon(object: any): object is SelectOptionWithIcon<T> {
    return 'icon' in object
  }
}
