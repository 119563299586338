<div class="select-container">
  <div class="select-row">
    <mat-select (closed)="_open=false"
                (opened)="_open=true"
                (selectionChange)="onSelected(selectedOption)"
                [(ngModel)]="selectedOption"
                [placeholder]="placeholder"
                [compareWith]="compareSelectedWithOptions"
                class="app-select chooseType"
                panelClass="select-options-container">
      <mat-select-trigger *ngIf="canHandleNullSelection || selectedOption"
                          class="chooseTypeSelection">

        <ng-container *ngIf="getSelectTriggerData() as data">
          <mat-icon *ngIf="data[1]!=null" [svgIcon]="data[1]" color="primary"></mat-icon>
          <span class="ellipsis-label">{{ data[0] }}</span>
        </ng-container>

      </mat-select-trigger>
      <mat-option *ngFor="let option of options"
                  [value]="option.value"
                  [disabled]="option.disabled"
                  class="app-option chooseTypeOption">
        <mat-icon *ngIf="isSelectOptionsWithIcon(option)"
                  [svgIcon]="option.icon" color="primary">
        </mat-icon>
        <span class="label">{{ option.displayName }}</span>
      </mat-option>
    </mat-select>
    <mat-icon (click)="toggleOptions()" *ngIf="!_open" class="arrow-key">
      keyboard_arrow_down
    </mat-icon>
    <mat-icon (click)="toggleOptions()" *ngIf="_open" class="arrow-key">
      keyboard_arrow_up
    </mat-icon>
    <div (click)="toggleOptions()" class="select-trigger-right"></div>
  </div>
  @if (_error !== '') {
    <div class="error">{{ _error }}</div>
  }
</div>

