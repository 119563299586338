import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {TimeNavigationService} from '../time-navigation.service'
import {DateUtils} from '../../util/date-utils'
import {v4 as uuidv4} from 'uuid'
import {hasPermission, Permission, User, UserService} from './user.service'
import {AbsenceEntriesService, AbsenceEntryEntity} from './absence-entries.service'
import {
  mergeDataWithModifiableObject,
  ModifiableDataObject,
  ModifiableField,
  modifyModifiableObject,
  Synchronizer
} from '../../util/synchronizer'
import {LocalUserPropertiesService} from '../local-user-properties.service'
import {Bouncer} from '../../util/bouncer'
import {AzureLogging} from '../azure-logging.service'
import {AbsenceEntryMutationResult} from './graphql/graphql-types'
import {deepCopy} from '../../util/copy'
import {AbsenceEntryTypeService} from './absence-entry-type.service'

const TIMEBOX_LOCAL_ABSENCE_ENTRIES_KEY = 'TimeboxLocalAbsenceEntriesV3'

const ABSENCE_ENTRY_ERROR_MAX_RETRIES = 3
const ABSENCE_ENTRIES_BOUNCE_TIMEOUT = 50


@Injectable({
  providedIn: 'root'
})
export class LocalAbsenceEntriesService extends Synchronizer<LocalAbsenceEntry> {

  private bouncer = new Bouncer(ABSENCE_ENTRIES_BOUNCE_TIMEOUT)

  private errorRetryCounter: Map<string, number> = new Map<string, number>()

  constructor(private timeNavigationService: TimeNavigationService,
              private absenceEntriesService: AbsenceEntriesService,
              private userService: UserService,
              private userPropertiesService: LocalUserPropertiesService,
              private absenceEntryTypeService: AbsenceEntryTypeService) {
    super()

    this.gatherDataForBulkRequest = true
  }

  private currentMonthSubject$ = new BehaviorSubject<AbsenceEntry[]>(undefined)

  get currentMonth$(): Observable<AbsenceEntry[]> {
    return this.currentMonthSubject$
  }

  private currentAbsenceEntriesOverflowSubject$ = new BehaviorSubject<AbsenceEntry[]>(undefined)

  get currentMonth(): AbsenceEntry[] {
    return this.currentMonthSubject$.getValue()
  }

  setAbsenceEntry(date: Date, absenceTypeId: number): void {
    const absenceEntry = this.dataArray.find((entry) => {
      return DateUtils.isSameDate(date, DateUtils.stringToDate(entry.date))
    })
    const hasHoliday = absenceEntry?.absenceTypeId.value === this.absenceEntryTypeService.holidayId
    if (absenceEntry) {
      if (!hasHoliday) {
        this.modifyLocalAbsenceEntry(absenceEntry, absenceTypeId)
      }
    } else {
      if (absenceTypeId != null) {
        const newEntry: LocalAbsenceEntry = {
          uuid: uuidv4(),
          absenceTypeId: {
            value: null,
            modified: false
          },
          externalStatus: '',
          date: DateUtils.dateToString(date),
          modified: null,
          mergedFrom: [],
          tracked: false
        }
        this.modifyLocalAbsenceEntry(newEntry, absenceTypeId)
        this.dataArray.push(newEntry)
      }
    }

    this.updateListenersForCurrentMonthObservable().then()
  }

  removeAllByPredicate(predicate: (entry: AbsenceEntry) => boolean): void {
    this.dataArray
      .filter(localEntry => predicate(toAbsenceEntry(localEntry)))
      .forEach((localEntry) => {
        this.modifyLocalAbsenceEntry(localEntry, null)
      })
    this.updateListenersForCurrentMonthObservable().then()
  }

  getAbsenceEntriesOnDate(date: Date): AbsenceEntry[] {
    const absenceEntryOfDate =
      this.dataArray
        .filter(entry => {
          return DateUtils.isSameDate(DateUtils.stringToDate(entry.date), date) && entry.absenceTypeId.value != null
        })

    return absenceEntryOfDate.map(toAbsenceEntry)
  }

  containsAbsenceEntryOnDate(date: Date): boolean {
    return this.getAbsenceEntriesOnDate(date).length > 0
  }

  public async loadLocalState(): Promise<void> {
    const localAbsenceEntries = await this.userPropertiesService.getProperty(TIMEBOX_LOCAL_ABSENCE_ENTRIES_KEY, null)

    if (localAbsenceEntries != null) {
      this.dataArray = localAbsenceEntries
      this.updateListenersForCurrentMonthObservable().then()
    }
  }

  protected async fetchRemoteData(): Promise<AbsenceEntryEntity[]> {
    return await this.absenceEntriesService.fetchAllUnsubmittedAbsenceEntries()
  }

  protected async mergeRemoteDataIntoLocalData(remoteEntries: any[]): Promise<void> {
    this.addRemoteEntriesToLocalStore(remoteEntries)
  }

  public async postSynchronize(): Promise<void> {
    this.dataArray.forEach(localEntry => localEntry.mergedFrom = [])
    this.updateListenersForCurrentMonthObservable().then()
  }

  public async saveLocalState(): Promise<any> {
    this.userPropertiesService.setProperty(TIMEBOX_LOCAL_ABSENCE_ENTRIES_KEY, this.dataArray).then()
  }

  /* ######################   Synchronization   ###################### */

  isAvailableFor(user: User): boolean {
    return hasPermission(user, Permission.ProcessAbsenceEntriesPermission) || hasPermission(user, Permission.ProcessHolidaysPermission)
  }

  private async deleteDuplicatedEntriesOnRemote(): Promise<void> {
    await Promise.all(this.dataArray.map(async (localAbsenceEntry) => {
      if (localAbsenceEntry.mergedFrom.length > 0) {
        localAbsenceEntry.uuid = localAbsenceEntry.mergedFrom[0].id
      }

      await localAbsenceEntry.mergedFrom
        .slice(1, localAbsenceEntry.mergedFrom.length)
        .map(absenceEntry => absenceEntry.id)
        .map(id => this.absenceEntriesService.delete(id))

    }))
  }

  protected async pushDataArrayToRemote(localAbsenceEntries: LocalAbsenceEntry[]): Promise<AbsenceEntryMutationResult> {
    return await this.absenceEntriesService.pushEntitiesToRemote(
      localAbsenceEntries.map(localEntry => {
        return toAbsenceEntryEntity(localEntry, this.userService.me.id)
      })
    )
  }

  private deleteLocalAbsenceEntry(uuid: string): void {
    this.dataArray = this.dataArray.filter((entry) => entry.uuid !== uuid)
  }

  private revertOrDeleteLocalAbsenceEntry(localAbsenceEntry: LocalAbsenceEntry, onRevert: () => void, onDelete: () => void): void {
    if (localAbsenceEntry.tracked) {
      mergeDataWithModifiableObject({
        absenceTypeId: localAbsenceEntry.mergedFrom[0].absenceTypeFK
      }, localAbsenceEntry)

      onRevert()
    } else {
      this.deleteLocalAbsenceEntry(localAbsenceEntry.uuid)
      onDelete()
    }
  }

  protected handleServerModificationResponseForModifiableObject(
    response: any, modifiedData: LocalAbsenceEntry, prePushData: LocalAbsenceEntry): void {
    const errorOfAbsenceEntry = response.errors.find(error => error.id === modifiedData.uuid)
    if (errorOfAbsenceEntry) {
      const userName = this.userService.me.firstName + ' ' + this.userService.me.lastName

      switch (errorOfAbsenceEntry.status) {
        case 403: {
          this.revertOrDeleteLocalAbsenceEntry(modifiedData, () => {
            const userIdOfAbsenceEntry = modifiedData.mergedFrom[0].userFK
            let message: string

            if (this.userService.me.id !== userIdOfAbsenceEntry) {
              message = 'Authorization failed: User is not allowed to manipulate absence entries of other users.'
              AzureLogging.logError(new Error(userName + ':\n' + message))

              return
            }

            message = `An unknown Error occured while handling response errors in LocalAbsenceEntriesService!
              Response: ${deepCopy(response)}
              Modified Data: ${deepCopy(modifiedData)}
              Pre-Synchronization Data: ${deepCopy(prePushData)}
            `
            console.error(message)
            AzureLogging.logError(new Error(userName + ':\n' + message))
          }, () => {
            const message = `An unknown error occurred! Resetting local changes and restart synchronisation!
              Response: ${JSON.stringify(deepCopy(response))}
              Modified Data: ${JSON.stringify(deepCopy(modifiedData))}
              Pre-Synchronization Data: ${JSON.stringify(deepCopy(prePushData))}
            `
            console.error(message)
            AzureLogging.logError(new Error(userName + ':\n' + message))
          })
          break
        }
        case 404: {
          this.deleteLocalAbsenceEntry(errorOfAbsenceEntry.id)

          const message = 'Absence-entry with id ' + errorOfAbsenceEntry.id + ' not found in database. Deleting locally...'
          console.warn(message)
          AzureLogging.logError(new Error(userName + ':\n' + message))

          break
        }
        case 409: {
          this.revertOrDeleteLocalAbsenceEntry(modifiedData, () => {
          }, () => {
          })

          const message = 'Cannot modify absence-entry because month is already submitted! Reverting to old state!'
          AzureLogging.logError(new Error(userName + ':\n' + message))
          console.error(message)

          break
        }
        case 500: {
          const uuid = modifiedData.uuid
          if (this.errorRetryCounter.get(uuid) === undefined) {
            this.errorRetryCounter.set(uuid, 0)
          }

          const newCount = this.errorRetryCounter.get(uuid) + 1
          this.errorRetryCounter.set(uuid, newCount)

          const message = 'Could not update absenceEntry on date' + modifiedData.date + '. Saving locally for next synchronisation!'
          console.warn(message)

          if (newCount < ABSENCE_ENTRY_ERROR_MAX_RETRIES) {
            modifiedData.modified = true
            this._modified$.next(true)
          } else {
            this.errorRetryCounter.delete(modifiedData.uuid)

            let action = 'Deleted'
            this.revertOrDeleteLocalAbsenceEntry(modifiedData,
              () => {
                action = 'Reverted'
              },
              () => {
              }
            )

            AzureLogging.logError(
              new Error(userName + ':\n' + action + ' absenceEntry due to unknown server error: \n' + errorOfAbsenceEntry.message))
          }

          return
        }
      }
    } else {
      this.errorRetryCounter.delete(modifiedData.uuid)
      modifiedData.tracked = true
    }

    this.errorRetryCounter.delete(modifiedData.uuid)
  }

  protected keepAfterSynchronization(entry: LocalAbsenceEntry): boolean {
    return entry.absenceTypeId.value >= 0
  }

  private modifyLocalAbsenceEntry(entry: LocalAbsenceEntry, absenceTypeId: number): void {
    if (absenceTypeId !== entry.absenceTypeId.value) {
      if (!entry.tracked && (absenceTypeId == null || absenceTypeId < 0)) {
        this.dataArray = this.dataArray.filter(localEntry => localEntry.uuid !== entry.uuid)
      } else {
        modifyModifiableObject({absenceTypeId}, entry)
      }

      this.updateListenersForCurrentMonthObservable().then(() => {
        // this.saveLocalState().then()
      })

      this._modified$.next(true)
    }
  }

  private addRemoteEntriesToLocalStore(remoteAbsenceEntries: AbsenceEntryEntity[]): void {
    this.dataArray = remoteAbsenceEntries.map(newLocalAbsenceEntryBasedOn)

    /*remoteAbsenceEntries
      .forEach((remoteEntry) => {
        const localEntry = this.dataArray.find((entry) => hashLocalEntry(entry) === localEntryHashOf(remoteEntry))
        if (localEntry !== undefined) {
          localEntry.mergedFrom.push(remoteEntry)
          localEntry.tracked = true
        } else {
          this.dataArray.push(newLocalAbsenceEntryBasedOn(remoteEntry))
        }
      })

    this.dataArray
      .filter(entry => !entry.modified && entry.mergedFrom.length > 0)
      .forEach(entry => {
        const data = {
          absenceTypeId: entry.mergedFrom[0].absenceTypeFK
        }

        mergeDataWithModifiableObject(data, entry)
      })

    this.deleteDuplicatedEntriesOnRemote().then()
    */
  }

  private async updateListenersForCurrentMonthObservable(): Promise<void> {
    return await this.bouncer.run(() => {
      const validEntries = this.dataArray.filter(entry =>
        entry.absenceTypeId.value != null && entry.absenceTypeId.value >= 0
      )

      const entriesCurrentMonth = validEntries
        .filter(entry => DateUtils.isInMonth(DateUtils.stringToDate(entry.date), this.timeNavigationService.currentMonth))
        .map(toAbsenceEntry)

      const entriesCurrentMonthOverflow = validEntries
        .filter(entry => DateUtils.isInMonthOverflowWeeks(DateUtils.stringToDate(entry.date), this.timeNavigationService.currentMonth))
        .map(toAbsenceEntry)


      this.currentAbsenceEntriesOverflowSubject$.next(entriesCurrentMonthOverflow)
      this.currentMonthSubject$.next(entriesCurrentMonth)
    })
  }

  protected getDataIdentifier(data: LocalAbsenceEntry): number | string {
    return data.uuid
  }

  /* ################################################################# */

}

function toAbsenceEntryEntity(localEntry: LocalAbsenceEntry, userId: number): AbsenceEntryEntity {
  return {
    id: localEntry.uuid,
    absenceTypeFK: localEntry.absenceTypeId.value,
    userFK: userId,
    date: localEntry.date,
    name: localEntry.name,
    externalStatus: localEntry.externalStatus
  }
}

function newLocalAbsenceEntryBasedOn(baseEntry: AbsenceEntryEntity): LocalAbsenceEntry {
  return {
    uuid: baseEntry.id,
    absenceTypeId: {
      value: baseEntry.absenceTypeFK,
      modified: false
    },
    date: baseEntry.date,
    modified: false,
    mergedFrom: [baseEntry],
    tracked: true,
    name: baseEntry.name,
    externalStatus: baseEntry.externalStatus
  }
}

function toAbsenceEntry(localEntry: LocalAbsenceEntry): AbsenceEntry {
  return {
    absenceTypeId: localEntry.absenceTypeId.value,
    date: DateUtils.stringToDate(localEntry.date),
    name: localEntry.name,
    externalStatus: localEntry.externalStatus
  }
}

// Why hash function when uuid is unique?
function hashLocalEntry(entry: LocalAbsenceEntry): string {
  return `${entry.date}`
}

function localEntryHashOf(absenceEntryEntity: AbsenceEntryEntity): string {
  return hashLocalEntry(newLocalAbsenceEntryBasedOn(absenceEntryEntity))
}


interface LocalAbsenceEntry extends ModifiableDataObject {
  uuid: string
  absenceTypeId: ModifiableField<number>
  date: string
  mergedFrom: AbsenceEntryEntity[]
  name?: string
  externalStatus: string
}

export interface AbsenceEntry {
  absenceTypeId: number
  date: Date
  name?: string
  externalStatus: string
}
