import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  standalone: true,
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(totalSeconds: number): string {
    if (isNaN(totalSeconds) || totalSeconds < 0) {
      return '00:00'
    }

    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)

    // Format the time components to ensure they are always two digits
    const hoursString = hours.toString().padStart(2, '0')
    const minutesString = minutes.toString().padStart(2, '0')

    // Return the formatted time (HH:MM)
    return `${hoursString}:${minutesString}`
  }
}
