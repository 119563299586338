<div [ngClass]="{'invisible': !dataAvailable}" class="table__container">
  <table #table (matSortChange)="sortData($event)"
         [dataSource]="dataSource"
         [ngClass]="{'table-fixed': columnSizes != undefined}"
         mat-table
         matSort
  >
    <ng-container *ngFor="let column of internalColumnDefinitions; let i = index"
                  matColumnDef="{{column.displayName}}">
      <th *matHeaderCellDef [ngStyle]="{width: getColumnSize(i)}" class="table_header" mat-header-cell>
        <div class="center-content-vertically" [ngClass]="getAlignmentClass(column.align)" >
          <span>{{ column.displayName }}</span>
          <div *ngIf="column.sortable" class="sort-icon-container">
            <mat-icon (click)="sortTableData(column.fieldName)" [class.active]="column.sortState==sortStateAsc"
                      class="not-selectable asc"
                      id="{{column.displayName}}_sortAsc">{{ sortStateAsc }}
            </mat-icon>
            <mat-icon (click)="sortTableData(column.fieldName)" [class.active]="column.sortState==sortStateDes"
                      class="not-selectable des"
                      id="{{column.displayName}}_sortDesc">{{ sortStateDes }}
            </mat-icon>
          </div>
        </div>
      </th>
      <td *matCellDef="let data" class="table-content" mat-cell>
        <ng-container [ngTemplateOutletContext]="{ $implicit: data }"
                      [ngTemplateOutlet]="getTemplateOfColumn(column)"></ng-container>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="getHeaderRowDefinition(); sticky: true" mat-header-row></tr>
    <tr (click)="select(row)"
        *matRowDef="let row; columns: getHeaderRowDefinition();"
        [class.selected]="isSelectedRow(row)"
        class="selectable"
        mat-row></tr>
  </table>
</div>
<mat-paginator
  *ngIf="!disablePaginator"
  class="mat-paginator-sticky"
  [pageSize]="selectedPageSize"
  [pageSizeOptions]="[12 ,15 , 25, 30]"
  showFirstLastButtons
  (page)="onSelectPageSize($event)"
></mat-paginator>

<div #loading *ngIf="!dataAvailable">
  <div class="progress-area">
    <mat-spinner></mat-spinner>
  </div>
</div>
<!--
  Example for sortableTableComponent:

  <app-sortable-table
    [columnDefinitions]="[
      {
        displayName: 'Column1',
        sortable: true,
        fieldName: column1Field <!- - The name of the field (of one item in data array) which is relevant for sorting the column - ->
       },                                                                     |
       {                                                                      |
        displayName: 'Column2',                                               |
        sortable: false,                                                 - - -
        fieldName:                                                      |
      }                                                                 |
    }"                                                                  |
    [data]="data">  <- - - - - - - - - - - - - - - - - - - - - - - - - -

  <!- - For every columnDefinition we entered we need a template which represents the internal html content of data in this column - ->
  <ng-template #Column1 let-data>
    <div>{{data.column1Value}}</div>
  </ng-template>

  <ng-template #Column2 let-data>
    <div>{{data.column2Value}}</div>
    <button>Upload</button>
  </ng-template>

</app-sortable-table>
-->
