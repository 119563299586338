import {Injectable} from '@angular/core'
import {LocalTimeEntriesService, TimeEntry} from './repository/local-time-entries.service'
import {TimeNavigationService} from './time-navigation.service'
import {TimeEntryTypeEntity, TimeEntryTypeService, Types} from './repository/time-entry-type.service'
import {DateUtils} from '../util/date-utils'
import {Observable, of} from 'rxjs'
import {catchError, map} from 'rxjs/operators'

export interface GrossNet {
  gross: number,
  net: number
}

const emptyAction = () => {
}

@Injectable({
  providedIn: 'root'
})
export class GrossNetCalculatorService {

  constructor(private localTimeEntriesService: LocalTimeEntriesService,
              private timeNavigationService: TimeNavigationService,
              private timeEntryTypeService: TimeEntryTypeService) {
  }

  getGrossAndNetForMonth(
    date: Date,
    action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction,
    predicate: (entry: TimeEntry) => boolean = () => true
  ): Observable<GrossNet> {
    const timeEntries = this.getTimeEntriesForMonth(date).filter(predicate);

    const grossAndNet: GrossNet = timeEntries
      .map((entry) => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId);
        return timeEntryType
          ? { entry, ratio: timeEntryType.ratio }
          : { entry, ratio: 0 };
      })
      .reduce(
        (acc, { entry, ratio }) => {
          const gross = entry.timeSpanInSecs;
          const net = this.calculateNet(gross, ratio);
          action(entry, gross, net);
          acc.gross += gross;
          acc.net += net;
          return acc;
        },
        { gross: 0, net: 0 } as GrossNet
      );

    return of(grossAndNet).pipe(
      catchError((error) => {
        console.error('Error calculating Gross and Net:', error);
        return of({ gross: 0, net: 0 });
      })
    );
  }

  private getTimeEntriesForMonth(date: Date): TimeEntry[] {
    const entries = this.localTimeEntriesService.currentMonthOverflow.concat(
      this.localTimeEntriesService.currentMonth
    );

    return entries.filter((entry) => DateUtils.isSameMonth(entry.date, date));
  }


  getGrossAndNetForCurrentMonthByPredicate(
    action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction,
    predicate: (entry: TimeEntry) => boolean = () => true
  ): GrossNet {
    const grossAndNet = this.localTimeEntriesService.currentMonth
      .filter(predicate)
      .map(entry => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId);
        return (timeEntryType != undefined)
          ? { key: entry, value: timeEntryType }
          : { key: entry, value: { ratio: 0 } };
      })
      .reduce((previousValue, pair: Pair<TimeEntry, TimeEntryTypeEntity>) => {
        const gross = pair.key.timeSpanInSecs;
        const ratio = pair.value.ratio;
        const net = this.calculateNet(gross, ratio);

        if (action != undefined) {
          action(pair.key, gross, net);
        }

        return {
          gross: previousValue.gross + gross,
          net: previousValue.net + net
        } as GrossNet;
      }, { gross: 0, net: 0 });

    return (grossAndNet != undefined) ? grossAndNet : { gross: 0, net: 0 };
  }


  private calculateNet(gross: number, ratio: number): number {
    const net = gross * ratio;
    return Math.floor((net + 59 /*seconds*/) / 60) * 60;
  }


  getGrossAndNetForCurrentMonth(action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction): GrossNet {
    return this.getGrossAndNetForCurrentMonthByPredicate(action, () => true)
  }

  getGrossAndNetForDay(date: Date, action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction): GrossNet {
    const grossAndNet = this.getTimeEntriesOfDay(date)
      .map(entry => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId)
        return (timeEntryType != undefined) ? {
          key: entry,
          value: timeEntryType
        } : {key: entry, value: {ratio: 0}}
      })
      .reduce((previousValue, pair: Pair<TimeEntry, TimeEntryTypeEntity>) => {
        const gross = pair.key.timeSpanInSecs
        const ratio = pair.value.ratio;
        const net = this.calculateNet(gross, ratio);
        if (action != undefined) {
          action(pair.key, gross, net)
        }
        return {
          gross: previousValue.gross + gross,
          net: previousValue.net + net
        } as GrossNet
      }, {gross: 0, net: 0})

    return (grossAndNet != undefined) ? grossAndNet : {gross: 0, net: 0}
  }

  getWorktimeOfDay(date: Date): number {
    return this.getTimeEntriesOfDay(date)
      .filter(timeEntry => {
        const timeEntryType = this.timeEntryTypeService.get(timeEntry.timeEntryTypeId)
        return (timeEntryType != undefined && (timeEntryType.type == Types.regular || timeEntryType.type == Types.specialActive))
      })
      .map(entry => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId)
        return (timeEntryType != undefined) ? {
          key: entry,
          value: timeEntryType
        } : {key: entry, value: {ratio: 0}}
      })
      .reduce((previousValue, pair: Pair<TimeEntry, TimeEntryTypeEntity>) => {
        const time = pair.key.timeSpanInSecs

        return previousValue + time
      }, 0)
  }

  getExtraPassiveTime(date: Date): number {
    return this.getTimeEntriesOfDay(date)
      .filter(timeEntry => {
        const timeEntryType = this.timeEntryTypeService.get(timeEntry.timeEntryTypeId)
        return (timeEntryType != undefined && timeEntryType.type == Types.specialPassive)
      })
      .map(entry => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId)
        return (timeEntryType != undefined) ? {
          key: entry,
          value: timeEntryType
        } : {key: entry, value: {ratio: 0}}
      })
      .reduce((previousValue, pair: Pair<TimeEntry, TimeEntryTypeEntity>) => {
        const time = pair.key.timeSpanInSecs * pair.value.ratio

        return previousValue + time
      }, 0)
  }

  getExtraActiveTime(date: Date): number {
    return this.getTimeEntriesOfDay(date)
      .filter(timeEntry => {
        const timeEntryType = this.timeEntryTypeService.get(timeEntry.timeEntryTypeId)
        return (timeEntryType != undefined && timeEntryType.type == Types.specialActive)
      })
      .map(entry => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId)
        return (timeEntryType != undefined) ? {
          key: entry,
          value: timeEntryType
        } : {key: entry, value: {ratio: 0}}
      })
      .reduce((previousValue, pair: Pair<TimeEntry, TimeEntryTypeEntity>) => {
        const time = (pair.key.timeSpanInSecs * pair.value.ratio) - pair.key.timeSpanInSecs

        return previousValue + time
      }, 0)
  }

  getPassiveTime(date: Date): number {
    return this.getTimeEntriesOfDay(date)
      .filter(timeEntry => {
        const timeEntryType = this.timeEntryTypeService.get(timeEntry.timeEntryTypeId)
        return (timeEntryType != undefined && timeEntryType.type == Types.specialPassive)
      })
      .map(entry => {
        const timeEntryType = this.timeEntryTypeService.get(entry.timeEntryTypeId)
        return (timeEntryType != undefined) ? {
          key: entry,
          value: timeEntryType
        } : {key: entry, value: {ratio: 0}}
      })
      .reduce((previousValue, pair: Pair<TimeEntry, TimeEntryTypeEntity>) => {
        const time = pair.key.timeSpanInSecs

        return previousValue + time
      }, 0)
  }

  getNetForCurrentMonth(action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction): number {
    return this.getGrossAndNetForCurrentMonth(action).net
  }

  getNetForDay(date: Date, action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction): number {
    return this.getGrossAndNetForDay(date, action).net
  }

  getGrossForDay(date: Date, action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction): number {
    return this.getGrossAndNetForDay(date, action).gross
  }

  getTimeEntriesOfDay(date: Date): TimeEntry[] {
    return (this.localTimeEntriesService.currentMonth != undefined) ?
      this.localTimeEntriesService.currentMonthOverflow.concat(this.localTimeEntriesService.currentMonth).filter(entry => {
        return DateUtils.isSameDate(entry.date, date)
      }) : []
  }

  getNetForMonth(
    date: Date,
    action: (entry: TimeEntry, gross: number, net: number) => void = emptyAction
  ): Observable<number> {
    return this.getGrossAndNetForMonth(date, action).pipe(map((gn) => gn.net));
  }
}

interface Pair<T, R> {
  key: T
  value: R
}
