import { Component, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { TimeNavigationService } from '../../services/time-navigation.service';
import {LocalTimeEntriesService, TimeEntry} from '../../services/repository/local-time-entries.service'
import { GrossNetCalculatorService } from '../../services/gross-net-calculator.service';
import { DateUtils } from '../../util/date-utils';
import { Observable, Subscription, of } from 'rxjs';
import { TimeTrackerService } from '../../services/time-tracker.service';
import {hasPermission, Permission, TimeSheetStatus, User, UserService} from '../../services/repository/user.service'
import { Month, SubmitMonthService } from '../../services/repository/submit-month.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TimeEntriesService } from '../../services/repository/time-entries.service';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogType } from '../confirm-dialog/confirm-dialog.component';


const SECONDS_IN_HOUR = 3600;
const EXCEEDING_HOURS = 40;

@Component({
  selector: 'app-time-tracker',
  templateUrl: './time-tracker.component.html',
  styleUrls: ['./time-tracker.component.scss'],
})
export class TimeTrackerComponent implements OnInit, OnDestroy {
  totalRemainderHours = 0;

  totalTargetTime = '';
  totalTargetSeconds = 0;

  totalWorkTimeSeconds = 0;

  currentRemainderTime = '';
  currentRemainderSeconds = 0;

  lastMonthRemainder = '';
  lastMonthRemainderSeconds = 0;

  totalRemainder = '';
  totalRemainderSeconds = 0;

  exceededTime = '';
  exceededTimeHoursFormat = '';
  exceededTimeHours = 0;
  workedPercentage = 0;
  exceededPercentage = 0;

  popupVisible = false;

  submittedMonths: Month[] = [];
  cumulativeTargetSeconds = 0;
  cumulativeTargetTime = '';

  isDataLoaded = false;

  saldoChangeValue: number | null = null;
  saldoChangeDisplay = '--';
  saldoChangeSeconds = 0;

  capMessage = '--'
  remove40hCap = false;
  showSaldoFeature = false

  private subscriptions = new Subscription();

  constructor(
    private localTimeEntriesService: LocalTimeEntriesService,
    private timeNavigationService: TimeNavigationService,
    private elRef: ElementRef,
    private timeTrackerService: TimeTrackerService,
    private submitMonthService: SubmitMonthService,
    private userService: UserService,
    private timeEntriesService: TimeEntriesService,
    private grossNetCalculatorService: GrossNetCalculatorService,
    private dialog: MatDialog
  ) {}

  private resetVariables(): void {
    this.totalRemainderHours = 0;

    this.totalTargetTime = '';
    this.totalTargetSeconds = 0;

    this.totalWorkTimeSeconds = 0;

    this.currentRemainderTime = '';
    this.currentRemainderSeconds = 0;

    this.lastMonthRemainder = '';
    this.lastMonthRemainderSeconds = 0;

    this.totalRemainder = '';
    this.totalRemainderSeconds = 0;

    this.exceededTime = '';
    this.exceededTimeHoursFormat = '';
    this.exceededTimeHours = 0;
    this.workedPercentage = 0;
    this.exceededPercentage = 0;

    this.cumulativeTargetSeconds = 0;
    this.cumulativeTargetTime = '';

    this.saldoChangeValue = null;
    this.saldoChangeDisplay = '--';
    this.saldoChangeSeconds = 0;
  }

  ngOnInit(): void {
    const userId = this.userService.me.id;
    const mainSubscription = this.timeNavigationService.currentMonth$
      .pipe(
        switchMap((currentMonth: Date) => {
          return this.fetchSubmitMonths(userId).pipe(
            switchMap(() => {
              this.isDataLoaded = false;
              const today = new Date();

              let day: number;

              if (DateUtils.isBeforeMonth(currentMonth, today)) {
                day = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
              } else if (DateUtils.isInMonth(currentMonth, today)) {
                day = today.getDate();
              } else {
                day = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
              }

              const currentMonthDate = new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth(),
                day
              );

              const formattedDate = formatDate(currentMonthDate, 'yyyy-MM-dd', 'en-US');

              if (
                hasPermission(this.userService.me, Permission.ProcessAbsenceEntriesPermission)
                && this.userService.me.timeSheetStatus === TimeSheetStatus.REQUIRED
              ) {
                this.showSaldoFeature = true
                return this.timeEntriesService.getCumulativeTargetWorkTime(formattedDate).pipe(
                  map((targetWorkingSeconds: number) => ({ targetWorkingSeconds, currentMonth }))
                );
              } else {
                return of({ targetWorkingSeconds: 0, currentMonth });
              }
            }),
            switchMap(({ targetWorkingSeconds, currentMonth }) => {
              this.cumulativeTargetSeconds = targetWorkingSeconds;
              this.cumulativeTargetTime = DateUtils.formatTimeValue(
                DateUtils.secondsToTimeValue(this.cumulativeTargetSeconds),
                false
              );

              this.totalTargetSeconds = this.cumulativeTargetSeconds;
              this.totalTargetTime = this.cumulativeTargetTime;

              return this.fetchCurrentMonthSaldoChange(userId, currentMonth).pipe(
                map(() => ({ currentMonth }))
              );
            }),
            switchMap(({ currentMonth }) => {
              return this.grossNetCalculatorService.getNetForMonth(currentMonth).pipe(
                map((netWorkTime: number) => ({ netWorkTime }))
              );
            })
          );
        })
      )
      .subscribe(
        ({ netWorkTime }) => {
          this.calculateWorkedHours(netWorkTime);
          this.isDataLoaded = true;
        },
        (error) => {
          console.error('Error fetching data:', error);
          this.isDataLoaded = true;
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: 'Fehler beim Laden der Daten',
              text: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
              type: ConfirmDialogType.ERROR,
            } as ConfirmDialogData,
          });
        }
      );

    const timeEntriesChangeSubscription = this.localTimeEntriesService.timeEntries$
      .pipe(
        switchMap((timeEntries: TimeEntry[]) => {
          const currentMonth = this.timeNavigationService.currentMonth;
          return this.grossNetCalculatorService.getNetForMonth(currentMonth).pipe(
            map((netWorkTime: number) => ({ netWorkTime }))
          );
        }),
        catchError((error) => {
          console.error('Error handling time entries change:', error);
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: 'Fehler beim Aktualisieren der Arbeitszeit',
              text: 'Es ist ein Fehler beim Aktualisieren der Arbeitszeit aufgetreten. Bitte versuchen Sie es später erneut.',
              type: ConfirmDialogType.ERROR,
            } as ConfirmDialogData,
          });
          return of({ netWorkTime: 0 });
        })
      )
      .subscribe(({ netWorkTime }) => {
        this.calculateWorkedHours(netWorkTime);
      });

    this.subscriptions.add(timeEntriesChangeSubscription);

    this.subscriptions.add(mainSubscription);

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchSubmitMonths(userId: number): Observable<void> {
    this.isDataLoaded = false;
    this.resetVariables();


    return this.submitMonthService.getSubmitMonthsOfUser(userId).pipe(
      map((months: Month[]) => {
        this.submittedMonths = months;

        const currentMonth = this.timeNavigationService.currentMonth;
        const currentMonthDate = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth(),
          1
        );

        const submittedMonthsWithDate = months.map((month) => ({
          ...month,
          date: this.parseMonthString(month.month),
        }));

        const priorMonths = submittedMonthsWithDate.filter((month) => {
          return month.date < currentMonthDate;
        });

        if (priorMonths.length > 0) {
          priorMonths.sort((a, b) => b.date.getTime() - a.date.getTime());
          const lastSubmittedMonth = priorMonths[0];

          this.lastMonthRemainderSeconds = lastSubmittedMonth.balance;
          const lastMonthRemainderSign = this.lastMonthRemainderSeconds < 0 ? '-' : '+';
          this.lastMonthRemainder =
            lastMonthRemainderSign +
            DateUtils.formatTimeValue(
              DateUtils.secondsToTimeValue(Math.abs(this.lastMonthRemainderSeconds)),
              false
            );
        } else {
          this.lastMonthRemainderSeconds = 0;
          this.lastMonthRemainder = '00:00';
        }
      }),
      catchError((error) => {
        console.error('Error fetching submit months:', error);
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Fehler beim Laden der Monatsdaten',
            text: 'Es ist ein Fehler beim Laden der Monatsdaten aufgetreten. Bitte versuchen Sie es später erneut.',
            type: ConfirmDialogType.ERROR,
          } as ConfirmDialogData,
        });
        return of();
      }),
      map(() => {})
    );
  }

  private fetchCurrentMonthSaldoChange(userId: number, currentMonth: Date): Observable<void> {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth() + 1;

    const lastDay = DateUtils.getLastDayOfMonth(currentMonth);

    const fromDate = `${year}-${String(month).padStart(2, '0')}-01`;
    const toDate = `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;

    return this.timeTrackerService.getBalanceCorrectionForUser(userId, fromDate, toDate).pipe(
      map((balances) => {
        const currentMonthEntry = balances.find((b) => {
          const balanceDate = new Date(b.month);
          return (
            balanceDate.getFullYear() === currentMonth.getFullYear() &&
            balanceDate.getMonth() === currentMonth.getMonth()
          );
        });

        if (currentMonthEntry) {
          this.remove40hCap = currentMonthEntry.remove40hCap
          this.timeTrackerService.setRemoveCap(this.remove40hCap);
          const newCapMessage = currentMonthEntry.comment || '--';
          this.capMessage = newCapMessage;
          this.saldoChangeValue = currentMonthEntry.saldoChange || null;
          this.updateSaldoChangeDisplay();
        } else {
          this.capMessage = '--';
          this.saldoChangeValue = null;
          this.saldoChangeDisplay = '--';
          this.saldoChangeSeconds = 0;
        }
      }),
      catchError((error) => {
        console.error('Error fetching saldo change:', error);
        this.saldoChangeValue = null;
        this.saldoChangeDisplay = '--';
        this.saldoChangeSeconds = 0;
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Fehler beim Aktualisieren der Saldo-Daten',
            text: 'Es ist ein Fehler beim Aktualisieren der Saldo-Daten aufgetreten. Bitte versuchen Sie es später erneut.',
            type: ConfirmDialogType.ERROR,
          } as ConfirmDialogData,
        });
        return of(); // Return an empty observable to complete the stream
      }),
      map(() => {}) // Map to void
    );
  }

  private updateSaldoChangeDisplay(): void {
    if (this.saldoChangeValue !== null) {
      const saldoChangeDisplaySign = this.saldoChangeValue < 0 ? '-' : '+';
      this.saldoChangeDisplay =
          saldoChangeDisplaySign +
        DateUtils.formatTimeValue(
          DateUtils.secondsToTimeValue(Math.abs(this.saldoChangeValue)),
          false
        );
      this.saldoChangeSeconds = this.saldoChangeValue;
    } else {
      this.saldoChangeDisplay = '--';
      this.saldoChangeSeconds = 0;
    }
  }

  private parseMonthString(monthString: string): Date {
    const parts = monthString.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;

    let date: Date;
    if (parts.length >= 2) {
      date = new Date(year, month, 1);
    } else {
      console.error('Unexpected date format:', monthString);
      date = new Date();
    }

    return date;
  }

  calculateWorkedHours(netWorkTime: number): void {
    this.totalWorkTimeSeconds = Number(netWorkTime);
    this.totalTargetSeconds = Number(this.totalTargetSeconds);
    this.lastMonthRemainderSeconds = Number(this.lastMonthRemainderSeconds);

    if (this.totalTargetSeconds === 0) {
      this.workedPercentage = 0;
    } else {
      this.workedPercentage = (this.totalWorkTimeSeconds / this.totalTargetSeconds) * 100;
      this.workedPercentage = Math.min(this.workedPercentage, 100);
    }

    this.currentRemainderSeconds = this.totalWorkTimeSeconds - this.totalTargetSeconds;
    this.currentRemainderSeconds = Number(this.currentRemainderSeconds);

    const currentRemainderSign = this.currentRemainderSeconds < 0 ? '-' : '';
    this.currentRemainderTime =
      currentRemainderSign +
      DateUtils.formatTimeValue(
        DateUtils.secondsToTimeValue(Math.abs(this.currentRemainderSeconds)),
        false
      );

    this.totalRemainderSeconds =
      this.lastMonthRemainderSeconds + this.currentRemainderSeconds + this.saldoChangeSeconds;

    this.totalRemainderSeconds = Number(this.totalRemainderSeconds);

    const totalRemainderHours = this.totalRemainderSeconds / SECONDS_IN_HOUR;
    this.totalRemainderHours = totalRemainderHours;

    if (totalRemainderHours > EXCEEDING_HOURS) {
      this.exceededTimeHours = parseFloat((totalRemainderHours - EXCEEDING_HOURS).toFixed(2));
      this.exceededTimeHoursFormat = DateUtils.formatTimeValue(
        DateUtils.secondsToTimeValue((totalRemainderHours - EXCEEDING_HOURS) * SECONDS_IN_HOUR),
        false
      );
    } else {
      this.exceededTimeHours = 0;
      this.exceededTimeHoursFormat = '';
    }

    const totalRemainderSign = this.totalRemainderSeconds < 0 ? '-' : '';
    this.totalRemainder =
      totalRemainderSign +
      DateUtils.formatTimeValue(
        DateUtils.secondsToTimeValue(Math.abs(this.totalRemainderSeconds)),
        false
      );

    if (this.totalWorkTimeSeconds > this.totalTargetSeconds) {
      const exceededTimeSeconds = this.totalWorkTimeSeconds - this.totalTargetSeconds;
      this.exceededPercentage = (exceededTimeSeconds / this.totalWorkTimeSeconds) * 100;
      this.exceededPercentage = Math.min(this.exceededPercentage, 100);
      this.exceededTime = DateUtils.formatTimeValue(
        DateUtils.secondsToTimeValue(exceededTimeSeconds),
        false
      );
    } else {
      this.exceededPercentage = 0;
    }
    this.timeTrackerService.setExceededHours(this.exceededTimeHours);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.popupVisible = false;
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this.popupVisible && !this.elRef.nativeElement.contains(event.target)) {
      this.popupVisible = false;
    }
  }

  togglePopup(): void {
    this.popupVisible = !this.popupVisible;
  }
}
