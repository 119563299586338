import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs'
import {HttpClient, HttpParams} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {catchError, map} from 'rxjs/operators'

export interface BalanceWithComments {
  userId: number;
  balance: number;
  month: string;
  comment: string | null;
  saldoChange?: number;
  remove40hCap: boolean;
}

interface ApiResponse<T> {
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class TimeTrackerService {
  private exceededHoursSource = new BehaviorSubject<number>(0);
  private balanceCorrectionSource = new Subject<void>();
  private removeCapSource = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  exceededHours$ = this.exceededHoursSource.asObservable();
  balanceCorrection$ = this.balanceCorrectionSource.asObservable();
  removeCap = this.removeCapSource.asObservable();

  setExceededHours(hours: number): void {
    this.exceededHoursSource.next(hours);
  }

  getExceededHours(): number {
    return this.exceededHoursSource.getValue();
  }

  getBalancesWithComments(userId: number, from: string, to: string): Observable<BalanceWithComments[]> {
    const params = new HttpParams().set('from', from).set('to', to);
    return this.http.get<BalanceWithComments[]>(`${environment.serverUrl}/eventstore/saldo/comments/${userId}`, { params });
  }

  getBalanceCorrectionForUser(userId: number, from: string, to: string): Observable<BalanceWithComments[]> {
    const params = new HttpParams().set('from', from).set('to', to);
    return this.http.get<ApiResponse<BalanceWithComments[]>>(`${environment.serverUrl}/eventstore/saldo/me`, { params }).pipe(
      map(response => response.data)
    );
  }

  setRemoveCap(cap: boolean): void {
    this.removeCapSource.next(cap);
  }

  getRemoveCap(): boolean {
    return this.removeCapSource.getValue();
  }

  triggerBalanceCorrection(): void {
    this.balanceCorrectionSource.next();
  }
}
